
import {mapMutations} from 'vuex'

export default {
	data() {
		return {
			activeNav: 0
		}
	},
	props: {
		list: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		...mapMutations({
			setModalMenu: 'modal/modal-menu/setModalMenu',
			setModalMarks: 'modal/modal-marks/setModalMarks',
			setModalSearch: 'modal/modal-search/setModalSearch',
		}),
		closeModals(){
			this.setModalMenu(false)
			this.setModalMarks(false)
			this.setModalSearch(false)
		}
	}
}
